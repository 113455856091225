/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * # Hero Section with Image
 * - Introductionary teaser on top of the page. Variant with image.
 */

/**
 * ## Imports
 */
import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import EcosiaBrowserImage from 'src/images/infinity/illustrations/ecosia-browser.png';
import SonicBrowserImage from 'src/images/infinity/illustrations/sonic-browser.png';
import FootballBrowserImage from 'src/images/infinity/illustrations/football-browser.png';
// import heroImageSourceLarge from '../../images/infinity/illustrations/hero_teaser_large.png';
// import heroImageSourceMobile from '../../images/infinity/illustrations/hero_teaser_mobile.png';
import styles from './HeroSectionWithImage.module.scss';

const slides = [
    {
        src: SonicBrowserImage,
        alt: 'Sonic Browser illustration',
    },
    {
        src: FootballBrowserImage,
        alt: 'Football Browser illustration',
    },
    {
        src: EcosiaBrowserImage,
        alt: 'Ecosia Browser illustration',
    },
];

/**
 * ## Types / Interfaces
 */
type HeroSectionWithImageProps = {
    clientName?: string;
}

/**
 * ## Component Output
 */
const HeroSectionWithImage: FC<HeroSectionWithImageProps> = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderWrapperRef = useRef(null);
    const intervalRef = useRef(null);
    const startXRef = useRef(0);
    const isDraggingRef = useRef(false);

    const handleSlide = (index) => () => {
        let currentIndex = index;

        if (index < 0) {
            currentIndex = slides.length - 1;
        } else if (index >= slides.length) {
            currentIndex = 0;
        }
        setCurrentSlide(currentIndex);
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            handleSlide(currentSlide + 1)();
        }, 5000);
        return () => clearInterval(intervalRef.current);
    }, [currentSlide]);

    const startDrag = (event) => {
        isDraggingRef.current = true;
        startXRef.current = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
        clearInterval(intervalRef.current);
    };

    const onDrag = (event) => {
        if (!isDraggingRef.current) return;
        const currentX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
        const diffX = startXRef.current - currentX;
        sliderWrapperRef.current.style.transform = `translateX(-${(currentSlide * 100) + (diffX / window.innerWidth) * 100}%)`;
    };

    const endDrag = (event) => {
        if (!isDraggingRef.current) return;
        isDraggingRef.current = false;
        const endX = event.type === 'touchend' ? event.changedTouches[0].clientX : event.clientX;
        const diffX = startXRef.current - endX;
        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                handleSlide(currentSlide + 1)();
            } else {
                handleSlide(currentSlide - 1)();
            }
        } else {
            handleSlide(currentSlide);
        }
        sliderWrapperRef.current.style.transform = `translateX(-${currentSlide * 100}%)`;
        intervalRef.current = setInterval(() => {
            handleSlide(currentSlide + 1)();
        }, 5000);
    };

    return (
        <>
            {/* Note: this is just a `<div>` tag and not a `<section>`/`<article>` tag
              * on purpose to avoid an `unnamed <body>`. */}
            <div className={styles['wrapper']}>
                <div className={styles['body']}>
                    <div className={styles['text-body']}>
                        <h1 className={styles['hero-headline-quinary']}>Infinity Browsers</h1>
                        <h2 className={styles['hero-headline-main']}>We create white-label browsers</h2>
                    </div>
                    {/* <div className={styles['hero-image-wrapper']}>
                        <img
                            alt="Custom Browser Visualization"
                            className={styles['hero-image']}
                            src={heroImageSourceMobile}
                            srcSet={`${heroImageSourceMobile} 786w, ${heroImageSourceLarge} 1042w`}
                            sizes="(max-width: 768px) 786px, 1042px"
                        />
                    </div> */}
                    <div className={styles['slider']}>
                        <div
                            className={styles['slides']}
                            ref={sliderWrapperRef}
                            onMouseDown={startDrag}
                            onMouseMove={onDrag}
                            onMouseUp={endDrag}
                            onMouseLeave={endDrag}
                            onTouchStart={startDrag}
                            onTouchMove={onDrag}
                            onTouchEnd={endDrag}
                            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                        >
                            {slides.map((slide, index) => (
                                <div key={index} className={clsx(styles['slide'], { [styles['slide--active']]: currentSlide === index })}>
                                    <img className={styles['slide-image']} src={slide.src} alt={slide.alt} />
                                </div>
                            ))}
                        </div>

                        <div className={styles['pagination']}>
                            {slides.map((_, index) => (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                <span
                                    key={index}
                                    className={clsx(styles['dot'], { [styles['dot--active']]: index === currentSlide })}
                                    onClick={handleSlide(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroSectionWithImage;